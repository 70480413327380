const theme = {
  palette: {
    blue: '#197dff',
    grey: '#787d87',
    lightGrey: '#bec8dc',
    darkGrey: '#23252d',
    darkWhite: '#f2f2f7',
    white: '#ffffff',
  },
  measurements: {
    navigation: {
      desktop: {
        height: 70,
      },
    },
    maxContentWidth: 1240,
  },
}

export default theme
