exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kundendaten-js": () => import("./../../../src/pages/kundendaten.js" /* webpackChunkName: "component---src-pages-kundendaten-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-projektablauf-js": () => import("./../../../src/pages/projektablauf.js" /* webpackChunkName: "component---src-pages-projektablauf-js" */),
  "component---src-pages-ueber-js": () => import("./../../../src/pages/ueber.js" /* webpackChunkName: "component---src-pages-ueber-js" */)
}

